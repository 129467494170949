.conference {
    padding-top: 30px;
    max-width: 1440px;
    margin-bottom: 10px;
}

.conference h2 {
    font-family: 'Baloo Bhai 2', serif;
    font-style: normal;
    font-weight: 900;
    font-size: 40px;
    line-height: 44px;
    padding-bottom: 10px;
    width: 100%;
}

.conference-info {
    border-bottom: 1px solid rgba(52, 51, 56, 0.1);
    padding-bottom: 20px;
}

.conference-description {
    font-family: 'Inter', serif;
    font-style: normal;
    font-weight: 300;
    font-size: 18px;
    line-height: 28px;
    color: #24232E;
    max-width: 570px;
    margin-bottom: 20px;
}

.peers-container {
    display: flex;
    flex-wrap: wrap;
    place-items: center;
}

.conference-invite {
    display: flex;
    align-items: center;
}

.conference-invite-link:hover {
    opacity: 0.85;
}

.conference-invite-link {
    display: inline-flex;
    align-items: center;
    margin-left: 8px;
    background: #DBDBDD;
    padding: 6px 10px;
    border-radius: 5px;
    cursor: pointer;
}

.copy-icon {
    margin-left: 8px;
}
