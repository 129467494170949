@keyframes load {
    0% {
        transform: rotate(0deg);
    }
    100% {
        transform: rotate(360deg);
    }
}

.loader {
    border: 4px solid transparent;
    border-top-color: #FFFFFF;
    animation: load 1s infinite linear;
    border-radius: 50%;
    width: 25px;
    height: 25px;
}
