.recordings-list {
    margin-top: 38px;
    max-width: 450px;
    min-width: 285px;
}

.list-title {
    margin-bottom: 11px;
    font-family: 'Baloo Bhai 2', serif;
    font-style: normal;
    font-weight: 900;
    font-size: 40px;
    line-height: 44px;
}

.accordion {
    list-style: none;
}

.accordion_item {
    border-top: 1px solid #9f9f9f;
}

.accordion-item-btn {
    background: #29a76b;
    color: #FFFFFF;
    display: flex;
    align-items: center;
    font-size: 14px;
    text-align: left;
    font-weight: 400;
    flex-wrap: wrap;
    width: 100%;
    justify-content: space-between;
    padding: 10px 14px;
    cursor: pointer;
    border: 1px solid #DBDBDD;
    border-radius: 0;
}

.control-icon {
    font-size: 20px;
}

.accordion-item-content {
    padding: 20px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.accordion-item-openned-wrapper {
    min-height: 0;
    overflow: hidden;
    transition: height ease 0.2s;
    background-color: #FFF;
}

.recording-link {
    margin: 5px 0;
    text-decoration: none;
    color: #000;
    height: 20px;
}

.recording-link:hover {
    text-decoration: underline;
    color: #29a76b;
}
