* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

body {
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Oxygen,
  Ubuntu, Cantarell, "Open Sans", "Helvetica Neue", sans-serif;
  background-color: rgba(246, 243, 238, 1);
  color: rgba(36, 35, 46, 1);
  min-height: 100vh;
}

html, body, #root {
  height: 100%;
  overflow-x: hidden;
}
