.peer-video {
    height: 250px;
    width: 350px;
    border-radius: 12px;
    object-fit: cover;
    margin-top: 10px;
}

.active {
    box-shadow: 0 0 8px 4px rgba(41, 167, 107, 1);
}

.local.peer-video {
    transform: scaleX(-1);
}

.peer-name {
    font-size: 14px;
    text-align: center;
    position: absolute;
    transform: translateX(8px) translateY(-34px);
    background: black;
    opacity: 0.7;
    color: white;
    border-radius: 6px;
    padding: 2px 7px 3px;
}

.peer-container {
    padding: 10px;
    position: relative;
}

.icon-muted {
    position: absolute;
    transform: translateX(316px) translateY(20px);
    z-index: 1;
    width: 24px;
    height: 24px;
    background: #24232E;
    opacity: 0.7;
    color: #FFF;
    justify-content: center;
    align-items: center;
    border-radius: 50%;
    display: none;
}

.enabled {
    display: flex;
}

.video-disabled {
    background: #24232E;
}

.peer-label {
    position: absolute;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 40px;
    font-weight: 500;
    color: rgba(41, 167, 107, 1);
    border-radius: 50%;
    width: 100px;
    height: 100px;
    background: rgba(0,0,0, 0.4);
    transform: translate(-50%, -45%);
    top: 47%;
    left: 50%;
}
