.finish-meeting {
    display: flex;
    flex-direction: column;
    margin-top: 38px;
}

.finish-meeting-title {
    margin-bottom: 11px;
    font-family: 'Baloo Bhai 2', serif;
    font-style: normal;
    font-weight: 900;
    font-size: 40px;
    line-height: 44px;
}

.finish-meeting-description {
    max-width: 500px;
    font-family: 'Inter', serif;
    font-style: normal;
    font-weight: 300;
    font-size: 18px;
    line-height: 28px;
    color: #24232E;
}

.finish-meeting-btn {
    margin-top: 30px;
    border: 1px solid transparent;
    border-radius: 8px;
    padding: 6px 20px;
    height: 48px;
    background-color: rgba(41, 167, 107, 1);
    color: white;
    font-family: 'Baloo Bhai 2', serif;
    font-size: 18px;
    cursor: pointer;
    width: fit-content;
}
