.main-layout {
    width: 100vw;
    height: 100%;
}

.layout-content-wrapper {
    padding: 0 44px 64px;
    max-width: 1440px;
    margin: 0 auto;
}
