.TextField-container {
    margin-top: 8px;
    margin-bottom: 8px;
    display: inline-flex;
    flex-direction: column;
    position: relative;
    min-width: 0;
    padding: 0;
}

.TextField-input {
    display: block;
    width: 100%;
    height: 48px;
    border-radius: 8px;
    border: 1px solid #DBDBDD;
    padding: 14px;
    background: #FFFFFF;
    font-size: 16px;
    color: inherit;
    font-family: inherit;
}

.TextField-input::placeholder {
    color: rgba(36, 35, 46, 1);
    font-family: 'Rubik', serif;
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 21px;
}

.TextField-error-text {
    color: rgb(211, 47, 47);
    font-size: 12px;
    margin: 3px 14px 0;
}

.TextField-error, .TextField-error::placeholder {
    color: rgb(211, 47, 47);
    border-color: rgb(211, 47, 47);
}

.TextField-error:focus-visible {
    border: 2px solid rgb(211, 47, 47);
    outline: none;
}
