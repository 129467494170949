.header {
    width: 100%;
    display: flex;
    align-items: center;
    height: 74px;
    background: rgba(36, 35, 45, 1);
    padding: 0 48px;
}

img {
    height: 44px;
    width: 193px;
}
