.preview {
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 20px;
}

.preview-container {
    width: 450px;
    min-width: 200px;
    height: 100%;
    padding: 20px;
    display: flex;
    align-items: center;
    flex-direction: column;
    background: #3c4043;
    border-radius: 12px;
}

.preview-select {
    position: relative;
    width: 100%;
    margin: 6px 0;
}

.preview-select-label {
    color: #FFFFFF;
    padding-left: 14px;
}

.video-container {
    position: relative;
}

.video {
    width: 100%;
    height: 250px;
    min-height: 310px;
    border-radius: 12px;
    transform: scaleX(-1);
}

.video-disabled {
    background: #202124;
}

.preview-control-bar {
    position: absolute;
    display: flex;
    justify-content: space-between;
    transform: translate(160px, -54px);
}

.preview-btn-control {
    display: flex;
    justify-content: center;
    align-items: center;
    background: rgba(0, 0, 0, 0.4);
    color: #FFF;
    width: 40px;
    height: 40px;
    border-radius: 8px;
    border: none;
    cursor: pointer;
    font-size: 18px;
}

.preview-btn-control:hover {
    opacity: 0.8;
}

.btn-audio {
    margin-right: 10px;
}

.preview-title {
    margin-top: 10px;
    color: #FFF;
}

.preview-btn:hover {
    opacity: 0.85;
}

.btn-settings {
    position: absolute;
    z-index: 1;
    right: 0;
    transform: translate(-10px, -54px);
}
