.start-page {
    display: flex;
    justify-content: space-between;
}

@media (max-width: 700px) {
    .start-page {
        flex-direction: column;
    }
}
