.control-bar {
    display: flex;
    position: fixed;
    bottom: 0;
    left: 0;
    height: 64px;
    width: 100%;
    align-items: center;
    justify-content: center;
    z-index: 10;
    background: rgba(36, 35, 46, 1);
}

.control-bar > *:not(:first-child) {
    margin-left: 8px;
}

.btn-control {
    font-size: 20px;
    border: 1px solid #37474f;
    width: 40px;
    height: 40px;
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: #3c4043;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.4);
    color: #FFF;
    cursor: pointer;
}

.btn-leave {
    background: #f44336;
}

.btn-leave:hover, .btn-control:hover {
    opacity: 0.8;
}

.icon-leave {
    transform: rotate(135deg);
}
