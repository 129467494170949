.btn-primary {
    display: flex;
    justify-content: center;
    align-items: center;
    align-content: center;
    border: 1px solid transparent;
    border-radius: 8px;
    position: relative;
    padding: 6px 20px;
    height: 48px;
    background-color: rgba(41, 167, 107, 1);
    color: white;
    font-family: 'Baloo Bhai 2', serif;
    font-size: 1.125rem;
    cursor: pointer;
}

.btn-primary:hover {
    opacity: 0.85;
}

