.select-device-container {
    position: relative;
    width: 100%;
}

.select-device-input {
    position: relative;
    display: inline-flex;
    align-items: center;
    width: 100%;
    height: 48px;
    background: #FFFFFF;
    border-radius: 6px;
    padding: 4px 14px;
    cursor: pointer;
    border: 1px solid #DBDBDD;
    margin: 8px 0 2px;
}

.input-label {
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    width: 95%;
}

.input-icon {
    position: absolute;
    right: 14px;
}

.select-device-list-wrapper {
    position: absolute;
    width: 100%;
    background: #FFFFFF;
    border-radius: 6px;
    padding: 4px 0;
    z-index: 10;
    box-shadow: 0 3px 5px -1px rgb(0 0 0 / 20%), 0 6px 10px 0 rgb(0 0 0 / 14%), 0 1px 18px 0 rgb(0 0 0 / 12%);
}

.select-device-list {
    list-style: none;
}

.select-device-list-item {
    cursor: pointer;
    display: flex;
    align-items: center;
    align-self: center;
    padding: 4px 14px;
    height: 48px;
}

.text-cut {
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
}

.select-device-list-item:hover {
    background: rgba(189, 193, 198, 0.4)
}
