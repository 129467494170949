.modal-wrapper {
    position: fixed;
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    top: 0;
    left: 0;
    z-index: 9999;
    background-color: rgba(22,25,31,.2);
    box-sizing: border-box;
}

.modal {
    background: #FFFFFF;
    max-width: 500px;
    width: 90%;
    height: 300px;
    padding: 20px;
    border-radius: 8px;
}

.modal-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 10px;
}

.modal-select {
    margin-top: 10px;
}

.modal-header-close-btn {
    border-radius: 50%;
    width: 34px;
    height: 34px;
    border: none;
    background: #FFFFFF;
    font-size: 24px;
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: center;
}

.modal-header-close-btn:hover {
    background: rgba(189, 193, 198, 0.4)
}
