.join-form {
    max-width: 450px;
    min-width: 285px;
    border-radius: 8px;
    margin-top: 38px;
    margin-right: 50px;
}

.header-title {
    margin-bottom: 11px;
    font-family: 'Baloo Bhai 2', serif;
    font-style: normal;
    font-weight: 900;
    font-size: 40px;
    line-height: 44px;
}

.header-description {
    font-family: 'Inter', serif;
    font-style: normal;
    font-weight: 300;
    font-size: 18px;
    line-height: 28px;
    color: #24232E;
}

.form-inputs {
    display: flex;
    flex-direction: column;
}
