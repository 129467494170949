.not-found-page {
    display: flex;
    flex-direction: column;
    text-align: center;
    align-items: center;
    justify-content: center;
    margin: 0 auto;
    max-width: 300px;
    height: 100%;
}

.not-found-code {
    color: rgba(41, 167, 107, 1);
    font-size: 54px;
}

.not-found-title {
    font-weight: 500;
    margin-bottom: 8px;
}

.not-found-text {
    margin-bottom: 12px;
}

.link-btn {
    background: rgba(41, 167, 107, 1);
    padding: 6px 14px;
    color: #FFF;
    text-decoration: none;
    border-radius: 6px;
}

.link-btn:hover {
    opacity: 0.8;
}
